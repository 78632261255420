import React from "react";
import { Global, css } from "@emotion/react";

export const breakpoints = {
	mobile: "@media (max-width: 640px)",
	mobileUp: "@media (min-width: 641px)",
	// nav: "@media (max-width: 768px)",
	tablet: "@media (max-width: 1024px)",
	desktop: "@media (max-width: 1230px)",
	desktopUp: "@media (min-width: 769px)",
};

export const mobile = breakpoints.mobile;
export const mobileUp = breakpoints.mobileUp;
// export const navBreakpoint = breakpoints.nav;
export const tablet = breakpoints.tablet;
export const desktop = breakpoints.desktop;
export const desktopUp = breakpoints.desktopUp;

const GlobalStyles = () => (
	<>
		<Global styles={reset} />
		<Global styles={styles} />
	</>
);

const styles = css`
	html {
		scroll-behavior: smooth;
		scroll-padding-top: var(--scroll-top);
		width: 100vw;
		overflow-x: hidden;

		${mobile} {
			scroll-padding-top: var(--scroll-top);
		}
	}

	body {
		width: 100vw;
		// overflow-x: hidden;
		font-family: Marfa, sans-serif;
		font-size: var(--body-size);
		line-height: var(--body-line);

		:has(.page-projects, .page-home, .page-archive, .page-error) {
			padding-top: var(--header-height);
		}
	}

	:root {
		--white: #ffffff;
		--black: #000000;
		--black-blend: #010101;
		--grey-dark: #b9b9b9;
		--grey-light: #e3e3e3;
		--beige: #cabfac;
		--fg: #000000;
		--bg: #ffffff;

		--xs: 12px;
		--s: 24px;
		--m: 36px;
		--l: 48px;

		--marfa: Marfa, sans-serif;
		--hermes: Hermes, sans-serif;

		--body-size: 18px;
		--body-line: calc(1.2 * var(--body-size));

		--accent-size: 13px;
		--accent-line: calc(1.35 * var(--accent-size));
		--accent-letter: 0.03em;

		--xl-size: 64px;
		--xl-line: var(--xl-size);

		--margin: 24px;
		--gap: 24px;
		--padding: var(--margin);
		--padding-sml: calc(var(--margin) * 0.8333333);
		--padding-xs: 12px;
		--padding-2x: 24px;
		--padding-med: 48px;
		--padding-lrg: 96px;
		--padding-xl: 200px;
		--transition-300: 300ms ease-in-out;

		--header-height: 57px;
		--header-bg: transparent;
		--scroll-top: var(--header-height);
		--slide-content-height: max(
			calc(100vh - (2 * var(--padding-lrg))),
			calc(700px - (2 * var(--padding-lrg)))
		);
		--slide-content-width: calc(100vw - (2 * var(--padding-lrg)));

		--border-black: 1px solid var(--black);
		--border-grey: 1px solid var(--grey-dark);

		--splash-timing: 750ms;
		--splash-ease: cubic-bezier(0.77, 0, 0.175, 1);
	}

	${tablet} {
		:root {
			--body-size: 16px;
			--body-line: calc(1.2 * var(--body-size));

			--accent-size: 12px;
			--accent-line: calc(1.35 * var(--accent-size));
			// --accent-letter: 0.02em;

			--xl-size: 37px;
			--xl-line: var(--xl-size);

			--margin: 18px;
			--gap: 18px;

			--padding-lrg: 48px;
			--padding-xl: 96px;

			--header-height: 44.5px;
		}
	}

	.accent {
	}

	${mobile} {
		:root {
			--slide-content-height: auto;
			--slide-content-width: calc(100vw - (2 * var(--margin)));
		}
	}

	p {
		&:empty {
			display: none;
		}
		// margin-bottom: 0;
		// &:last-child {
		// 	margin-bottom: 0;
		// }
	}

	h1,
	.h1,
	h2,
	.h2,
	h3,
	.h3 {
		cursor: default;
		// font-family: var(--sans);
		// font-weight: 700;
		// font-size: var(--h1-size);
		// line-height: var(--h1-line);
		// letter-spacing: 0.007em;
	}

	h3,
	.h3 {
		// font-size: var(--serif-xl-size);
		// line-height: var(--serif-xl-size);
	}

	a,
	a * {
		cursor: pointer;
	}
`;
const reset = css`
	body {
		margin: 0px;
		min-height: 100%;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: inherit;
		font-size: inherit;
	}

	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
		padding: 0;
	}

	ul,
	ol,
	blockquote {
		margin: 0;
		padding: 0;
	}

	li {
		// margin: 0.5em 0;
		margin: 0;
	}

	a {
		color: inherit;
		text-decoration: none;
		transition: opacity 300ms ease-in-out;

		> * {
			transition: opacity 300ms ease-in-out;
		}

		@media (hover: hover) {
			&:hover,
			&:hover > * {
				opacity: 0.5;
			}
		}
	}

	* {
		box-sizing: border-box;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		/*  This prevents chrome from annoyingly adjusting the scroll to try and compensate for moving elements */
		overflow-anchor: none;
	}

	button {
		text-align: left;
		width: fit-content;
		-webkit-appearance: none;
		border: none;
		background: none;
		font-size: inherit;
		font-family: inherit;
		cursor: pointer;
		padding: 0;
		margin: 0;
	}

	input,
	select {
		border-radius: 0;
		-webkit-appearance: none;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border: none;
		-webkit-text-fill-color: inherit;
		-webkit-box-shadow: 0 0 0px 0px transparent inset;
		transition: background-color 5000000s ease-in-out 0s;
	}

	/* Prevent safari blue links */
	button,
	option {
		color: inherit;
	}
`;

export default GlobalStyles;
