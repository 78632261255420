import React, { useState, useEffect, useRef } from "react";
import * as logoData from "../utils/logoData.json";
import styled from "@emotion/styled";
import lottie from "lottie-web";
import { useLocation } from "@reach/router";
import { mobile, mobileUp } from "../styles/global";
import { Link } from "gatsby";
import { useSiteState } from "../context/siteContext";
import useBreakpoint from "../utils/useBreakpoint";
// import { isMobile } from "detect-touch-device";

const Logo = ({ className, splashLogo }) => {
	const containerRef = useRef();
	const wrapRef = useRef();
	const [animation, setAnimation] = useState();
	const [siteState, setSiteState] = useSiteState();
	const location = useLocation();
	const { isMobile } = useBreakpoint();
	// const [location, setLocation] = useState();

	useEffect(() => {
		if (containerRef?.current) {
			const anim = lottie.loadAnimation({
				container: containerRef.current,
				renderer: "svg",
				loop: false,
				autoplay: false,
				animationData: logoData,
			});
			setAnimation(anim);
		}
	}, [containerRef]);

	const endframe = 69;
	const startframe = 36;

	const logoHoverStart = () => {
		if (siteState.entered && !isMobile) {
			animation?.setDirection(-1);
			animation?.play();
		}
	};

	const logoHoverEnd = () => {
		if (siteState.entered && !isMobile) {
			animation?.setDirection(1);
			animation?.play();
		}
	};

	useEffect(() => {
		if (animation) {
			animation?.goToAndPlay(69, true);
			// animation.play();
			// if (location !== "/") {
			// } else {
			// 	animation.onComplete = () => {
			// 		setComplete(true);
			// 	};
			// }
		}
	}, [animation]);

	return (
		<Wrap
			onMouseOver={logoHoverStart}
			onMouseLeave={logoHoverEnd}
			className={className}
			ref={wrapRef}
		>
			<Link to={"/"}>
				<div ref={containerRef}></div>
			</Link>
		</Wrap>
	);
};

const Wrap = styled.div`
	svg {
		fill: var(--fg);

		* {
			fill: var(--fg);
		}
	}

	a,
	a * {
		&:hover {
			opacity: 1 !important;
		}
	}

	${mobile} {
		z-index: 5;
		// width: 270px;

		div {
			width: fit-content;
			// height: 27px;
		}
	}
`;

export default Logo;
