import { graphql, useStaticQuery } from "gatsby";

const useSiteSettings = () => {
	const siteSettings = useStaticQuery(graphql`
		query generalQuery {
			sanityGeneral {
				acknowledgement: _rawAcknowledgement
				siteTitle
				address: _rawAddress
				email
				tel
				instagramUrl
				metaDescription
				socialImage {
					asset {
						_id
					}
				}
			}
		}
	`);

	return siteSettings?.sanityGeneral;
};

export default useSiteSettings;
