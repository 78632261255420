import React, { useState, useContext } from "react";
import PropTypes from "prop-types";

const initialSiteState = {
	pageTitle: null,
	// entered: false,
	// textEntered: false,
};

export const SiteContext = React.createContext({
	siteState: initialSiteState,
	setSiteState: undefined,
});

export const SiteStore = ({ children }) => {
	const [siteState, setSiteState] = useState(initialSiteState);
	return (
		<SiteContext.Provider
			value={{
				siteState: siteState,
				setSiteState: setSiteState,
			}}
		>
			{children}
		</SiteContext.Provider>
	);
};

SiteStore.propTypes = {
	children: PropTypes.node,
};

// hook to access siteState globally
export const useSiteState = () => {
	const { siteState, setSiteState } = useContext(SiteContext);
	return [siteState, setSiteState];
};
