import React from "react";
import { css } from "@emotion/react";

export const Logo = ({ className, fill }) => (
	<svg
		className={className}
		width="223"
		height="72"
		viewBox="0 0 223 72"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5.35743 71.709C7.63215 71.709 9.11566 70.7212 9.90687 68.9432L15.5442 55.3119H35.4233C38.5881 55.3119 40.7639 52.0523 39.5771 49.1878C38.8848 47.5085 37.2035 46.422 35.4233 46.422H12.0827C10.3025 46.422 8.62116 47.5085 8.02775 49.1878L1.2036 65.5848C0.0167874 68.4493 2.1926 71.709 5.35743 71.709ZM62.0276 71.709C65.7858 71.709 67.7638 68.5481 66.577 65.5848L40.8628 3.05873C40.1705 1.37952 38.4892 0.292969 36.709 0.292969C32.9508 0.292969 30.8738 3.45384 32.0606 6.41716L57.8737 68.9432C58.5661 70.6224 60.1485 71.709 62.0276 71.709ZM161.199 71.709C163.474 71.709 164.958 70.7212 165.749 68.9432L171.386 55.3119H191.265C194.43 55.3119 196.606 52.0523 195.419 49.1878C194.727 47.5085 193.045 46.422 191.265 46.422H167.925C166.144 46.422 164.463 47.5085 163.87 49.1878L157.046 65.5848C155.859 68.4493 158.035 71.709 161.199 71.709ZM217.87 71.709C221.628 71.709 223.606 68.5481 222.419 65.5848L196.705 3.05873C196.012 1.37952 194.331 0.292969 192.551 0.292969C188.793 0.292969 186.716 3.45384 187.903 6.41716L213.716 68.9432C214.408 70.6224 215.99 71.709 217.87 71.709ZM111.704 4.9355C111.704 7.1086 113.88 9.28171 117.342 9.28171C122.781 9.28171 127.726 11.0597 129.309 16.0973C129.902 17.8753 131.682 19.357 134.254 19.357C143.551 19.357 137.122 0.292969 117.441 0.292969C113.682 0.292969 111.704 2.16974 111.704 4.9355ZM88.0672 71.709C90.8364 71.709 93.0122 69.5359 93.0122 66.7701V5.23184C93.0122 2.46607 90.8364 0.292969 88.0672 0.292969C85.298 0.292969 83.1221 2.46607 83.1221 5.23184V66.7701C83.1221 69.5359 85.298 71.709 88.0672 71.709ZM117.342 71.709C151.957 71.709 151.858 29.2347 117.342 29.2347C114.573 29.2347 111.704 30.4201 111.704 33.8773C111.704 36.4455 113.781 38.3222 116.353 38.3222C138.902 38.3222 138.902 62.6215 116.353 62.6215C113.781 62.6215 111.704 64.4982 111.704 67.0665C111.704 70.5237 114.573 71.709 117.342 71.709Z"
			fill={fill}
		/>
	</svg>
);

export const LogoFull = ({ className, fill }) => (
	<svg
		className={className}
		version="1.1"
		id="Layer_2_00000028299069814989035630000009147775920035569855_"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		// x="0px"
		// y="0px"
		viewBox="0 0 1109.7 71.8"
		// style="enable-background:new 0 0 1109.7 71.8;"
		xmlSpace="preserve"
	>
		<g className="body">
			<path
				d="M98.3,53.4c0,1.9-1.5,3.4-3.4,3.4s-3.4-1.5-3.4-3.4V36.3c0-2.7-0.2-4.8-0.9-6.6c-1.4-3.9-4.8-6.4-10-6.4s-8.9,2.7-10.7,5.6
		c-1.5,2.5-2.2,5.1-2.2,7.9v16.5c0,1.9-1.5,3.4-3.4,3.4s-3.4-1.5-3.4-3.4V21c0-1.9,1.5-3.4,3.4-3.4c1.9,0,3.4,1.5,3.4,3.4v2.3
		c3.2-4,7.8-6.1,13.1-6.1c6.7,0,12.1,2.9,14.9,7.6c2,3.3,2.6,7.5,2.6,11.9C98.3,36.6,98.3,53.4,98.3,53.4z"
			/>
			<path
				d="M146.7,56.8c-1.9,0-3.4-1.5-3.4-3.4v-1.3c-3.3,3.2-7.8,5.1-13.1,5.1c-11.3,0-20.1-8.9-20.1-20s8.7-20.1,20-20.1
		c5.4,0,9.9,1.9,13.2,5.1v-15c0-1.9,1.5-3.4,3.4-3.4s3.4,1.5,3.4,3.4v46.1C150.2,55.3,148.6,56.8,146.7,56.8L146.7,56.8z
		 M130.2,23.4c-7.7,0-13.2,6.1-13.2,13.8S122.5,51,130.1,51c7.6,0,13.2-6,13.2-13.8S137.9,23.4,130.2,23.4z"
			/>
			<path
				d="M193.5,28c-1.1,0-1.8-0.3-2.7-1.4c-2.3-2.6-5.4-3.2-8.6-3.2c-3.7,0-6.7,1.2-8.4,2.6c-2.3,1.7-3.8,4-3.8,8v19.5
		c0,1.9-1.5,3.4-3.4,3.4s-3.4-1.5-3.4-3.4V21c0-1.9,1.5-3.4,3.4-3.4S170,19,170,21v1.6c1.3-1.2,2.9-2.4,5.1-3.4c2.3-1.1,4.8-2,8.4-2
		c4.7,0,9.6,1.9,12.2,5.4c0.4,0.6,0.9,1.3,0.9,2.2C196.6,26.5,195.3,28,193.5,28z"
			/>
			<path
				d="M239.3,39.7h-27.6c0.9,6.3,5.7,11.3,12.2,11.3c4.4,0,7.3-1.5,10.3-4.5c0.8-0.8,1.5-1.3,2.6-1.3c1.7,0,3.1,1.3,3.1,3.2
		c0,0.9-0.4,1.7-0.9,2.3c-3.8,4.2-9.2,6.5-14.6,6.5c-11.1,0-19.6-8.5-19.6-20s8.4-20.1,19.3-20.1s18.5,8.2,18.5,19.3
		C242.5,38.2,241.4,39.7,239.3,39.7L239.3,39.7z M224.2,23.2c-6,0-11.4,4-12.2,10.5h23.7C234.9,27.7,230.4,23.2,224.2,23.2z"
			/>
			<path
				d="M303.2,22l-10.6,32.5c-0.4,1.4-1.8,2.3-3.3,2.3h-2.4c-1.2,0-2.3-0.7-2.6-1.9l-5.9-17c-0.6-1.6-0.6-3.7-0.6-3.7h-0.1
		c0,0-0.1,2.2-0.6,3.7l-5.9,17c-0.4,1.2-1.5,1.9-2.6,1.9h-2.4c-1.4,0-2.8-1-3.2-2.3L252.2,22c-0.1-0.2-0.1-0.7-0.1-1.1
		c0-1.9,1.5-3.4,3.4-3.4c1.5,0,2.8,0.9,3.3,2.4l8.2,26.6c0.4,1.4,0.5,3.6,0.5,3.6h0.1c0,0,0-2.1,0.5-3.6l6.9-19.9
		c0.4-1.2,1.5-1.9,2.6-1.9s2.2,0.7,2.6,1.9l6.9,19.9c0.5,1.5,0.5,3.6,0.5,3.6h0.1c0,0,0.1-2.1,0.5-3.6l8.3-26.6
		c0.4-1.5,1.8-2.4,3.2-2.4c2,0,3.4,1.5,3.4,3.4C303.3,21.4,303.3,21.8,303.2,22L303.2,22z"
			/>
		</g>
		<g className="body">
			<path
				d="M400.9,21c0-1.9,1.5-3.4,3.4-3.4s3.4,1.5,3.4,3.4V38c0,2.7,0.2,4.8,0.9,6.6c1.4,3.9,4.8,6.4,10,6.4s8.9-2.7,10.7-5.6
		c1.5-2.5,2.2-5.1,2.2-7.9V21c0-1.9,1.5-3.4,3.4-3.4s3.4,1.5,3.4,3.4v32.5c0,1.9-1.5,3.4-3.4,3.4s-3.4-1.5-3.4-3.4v-2.3
		c-3.2,4-7.8,6.1-13.1,6.1c-6.7,0-12.1-2.9-15-7.5c-2-3.3-2.6-7.5-2.6-11.9L400.9,21L400.9,21z"
			/>
			<path
				d="M481.8,28c-1.1,0-1.8-0.3-2.7-1.4c-2.3-2.6-5.4-3.2-8.6-3.2c-3.7,0-6.7,1.2-8.4,2.6c-2.3,1.7-3.8,4-3.8,8v19.5
		c0,1.9-1.5,3.4-3.4,3.4s-3.4-1.5-3.4-3.4V21c0-1.9,1.5-3.4,3.4-3.4s3.4,1.5,3.4,3.4v1.6c1.3-1.2,2.9-2.4,5.1-3.4
		c2.3-1.1,4.8-2,8.4-2c4.7,0,9.6,1.9,12.2,5.4c0.4,0.6,0.9,1.3,0.9,2.2C485,26.5,483.7,28,481.8,28L481.8,28z"
			/>
			<path
				d="M533,55c0,5.9-1.7,10-6.1,13.2c-3.5,2.5-7.8,3.7-14.2,3.7c-5,0-10.2-1.3-14.1-3.7c-0.9-0.5-1.8-1.5-1.8-3
		c0-1.8,1.2-3.3,3.1-3.3c0.7,0,1.3,0.2,2,0.6c3.4,2,6.2,3.2,10.9,3.2c4.4,0,8-0.7,10.5-2.6c2-1.5,2.9-4,2.9-8.1V52
		c-3.3,3.2-7.8,5.2-13.1,5.2c-11.3,0-20.1-8.7-20.1-20s8.9-20.1,20-20.1c5.3,0,9.9,2,13.2,5.3V21c0-1.9,1.5-3.4,3.4-3.4
		s3.4,1.5,3.4,3.4L533,55L533,55z M513,23.4c-7.7,0-13.2,6.1-13.2,13.8S505.1,51,513,51c7.8,0,13.2-6.2,13.2-13.8
		S520.8,23.4,513,23.4z"
			/>
			<path
				d="M578.5,39.7h-27.5c0.9,6.3,5.7,11.3,12.2,11.3c4.4,0,7.3-1.5,10.3-4.5c0.8-0.8,1.5-1.3,2.6-1.3c1.7,0,3.1,1.3,3.1,3.2
		c0,0.9-0.4,1.7-1,2.3c-3.8,4.2-9.2,6.5-14.7,6.5c-11.1,0-19.6-8.5-19.6-20s8.4-20.1,19.3-20.1s18.5,8.2,18.5,19.3
		C581.7,38.2,580.5,39.7,578.5,39.7L578.5,39.7z M563.3,23.2c-6,0-11.4,4-12.2,10.5h23.7C574.1,27.7,569.5,23.2,563.3,23.2z"
			/>
			<path
				d="M623.8,55.1c-3.5,1.6-8.1,2.1-12.4,2.1c-9.2,0-15.3-3.7-17.8-6.3c-0.7-0.7-1.1-1.7-1.1-2.4c0-1.7,1.2-3.4,3.4-3.4
		c0.7,0,1.6,0.4,2.3,1c2.7,2.3,6.2,5,13.7,5c3.4,0,6.1-0.4,8.1-1.2c2.9-1.2,4-3.4,4-4.9c0-3.2-2.3-4-5.9-4.8
		c-1.9-0.4-9.3-1.2-11.4-1.5c-6.5-0.9-12.6-3.2-12.6-10.5c0-1.7,0.4-3.3,1.4-4.8c2.2-3.6,7.2-6.3,16-6.3c6.7,0,11.3,1.5,15.1,4
		c1.2,0.8,1.8,1.7,1.8,2.7c0,1.6-1.2,3.3-3,3.3c-0.7,0-1.6-0.4-2.3-0.8c-3.3-1.9-6.7-3.1-11.7-3.1c-9.1,0-10.4,3.1-10.4,5
		c0,3,2.7,4.1,7.3,4.7c2.3,0.3,8.4,1,10.8,1.4c5.5,1,11.7,3,11.7,10.7C630.9,49.7,628,53.2,623.8,55.1L623.8,55.1z"
			/>
		</g>
		<g className="body">
			<path
				d="M763,28c-1.1,0-1.8-0.3-2.7-1.4c-2.3-2.6-5.3-3.2-8.6-3.2c-3.7,0-6.7,1.2-8.4,2.6c-2.3,1.7-3.8,4-3.8,8v19.5
		c0,1.9-1.5,3.4-3.4,3.4s-3.4-1.5-3.4-3.4V21c0-1.9,1.5-3.4,3.4-3.4s3.4,1.5,3.4,3.4v1.6c1.3-1.2,2.9-2.4,5.1-3.4
		c2.3-1.1,4.8-2,8.4-2c4.7,0,9.6,1.9,12.2,5.4c0.4,0.6,0.9,1.3,0.9,2.2C766.2,26.5,764.9,28,763,28L763,28z"
			/>
			<path
				d="M807.8,30.6c-1.3,0-2.1-0.7-2.8-1.6c-2.6-3.5-6.1-5.6-10.6-5.6c-7.7,0-13.2,6.1-13.2,13.8S786.7,51,794.3,51
		c4.5,0,8.1-2,10.6-5.6c0.7-1,1.5-1.6,2.8-1.6c1.8,0,3.3,1.5,3.3,3.3c0,0.7-0.2,1.4-0.7,2c-3.5,5-9.4,8.2-16,8.2
		c-11.1,0-20.1-8.9-20.1-20s8.8-20.1,20-20.1c6.7,0,12.7,3.2,16.1,8.2c0.4,0.6,0.7,1.2,0.7,2C811.1,29.2,809.4,30.6,807.8,30.6
		L807.8,30.6z"
			/>
			<path
				d="M859.8,53.4c0,1.9-1.5,3.4-3.4,3.4s-3.4-1.5-3.4-3.4V36.3c0-2.7-0.2-4.8-0.9-6.6c-1.4-3.9-4.8-6.4-10-6.4
		s-8.9,2.7-10.7,5.6c-1.5,2.5-2.2,5.1-2.2,7.9v16.5c0,1.9-1.5,3.4-3.4,3.4s-3.4-1.5-3.4-3.4V7.3c0-1.9,1.5-3.4,3.4-3.4
		s3.4,1.5,3.4,3.4v15.9c3.2-4,7.8-6.1,13.1-6.1c6.7,0,12.1,2.9,15,7.6c2,3.3,2.6,7.5,2.6,11.9V53.4L859.8,53.4z"
			/>
			<circle cx="876.8" cy="5.3" r="5.3" />
			<path d="M880.3,53.4c0,1.9-1.5,3.4-3.4,3.4s-3.4-1.5-3.4-3.4V21c0-1.9,1.5-3.4,3.4-3.4s3.4,1.5,3.4,3.4V53.4z" />
			<path
				d="M926.3,53.4c-2.4,2.4-5.7,3.8-9.3,3.8c-4.3,0-7.8-1.5-10.5-4.8c-1.8-2.2-2.7-4.9-2.7-8.9V23.7h-7.6c-1.8,0-3.2-1.4-3.2-3.1
		s1.4-3.1,3.1-3.1h7.7v-11c0-1.9,1.5-3.4,3.4-3.4s3.4,1.5,3.4,3.4v11h10.4c1.8,0,3.2,1.4,3.2,3.1s-1.4,3.1-3.1,3.1h-10.5V44
		c0,1.6,0.4,3.4,1.2,4.6c1.1,1.5,3.1,2.4,5,2.4c1.7,0,3.2-0.7,4.7-1.9c0.9-0.7,1.8-1.3,2.7-1.3c1.8,0,3.1,1.3,3.1,3.2
		C927.5,51.9,927,52.8,926.3,53.4L926.3,53.4z"
			/>
			<path
				d="M970.9,39.7h-27.5c0.9,6.3,5.7,11.3,12.2,11.3c4.4,0,7.3-1.5,10.3-4.5c0.8-0.8,1.5-1.3,2.6-1.3c1.7,0,3.1,1.3,3.1,3.2
		c0,0.9-0.4,1.7-1,2.3c-3.8,4.2-9.2,6.5-14.7,6.5c-11.1,0-19.6-8.5-19.6-20s8.4-20.1,19.3-20.1s18.5,8.2,18.5,19.3
		C974.1,38.2,973,39.7,970.9,39.7L970.9,39.7z M955.8,23.2c-6,0-11.4,4-12.2,10.5h23.7C966.5,27.7,962,23.2,955.8,23.2z"
			/>
			<path
				d="M1016.9,30.6c-1.3,0-2.1-0.7-2.8-1.6c-2.6-3.5-6.1-5.6-10.6-5.6c-7.7,0-13.2,6.1-13.2,13.8s5.5,13.8,13.1,13.8
		c4.5,0,8.1-2,10.6-5.6c0.7-1,1.5-1.6,2.8-1.6c1.8,0,3.3,1.5,3.3,3.3c0,0.7-0.2,1.4-0.7,2c-3.5,5-9.4,8.2-16,8.2
		c-11.1,0-20.1-8.9-20.1-20s8.8-20.1,20-20.1c6.7,0,12.7,3.2,16.1,8.2c0.4,0.6,0.7,1.2,0.7,2C1020.2,29.2,1018.5,30.6,1016.9,30.6
		L1016.9,30.6z"
			/>
			<path
				d="M1060.5,53.4c-2.4,2.4-5.7,3.8-9.3,3.8c-4.3,0-7.8-1.5-10.5-4.8c-1.8-2.2-2.7-4.9-2.7-8.9V23.7h-7.6
		c-1.8,0-3.2-1.4-3.2-3.1s1.4-3.1,3.1-3.1h7.7v-11c0-1.9,1.5-3.4,3.4-3.4s3.4,1.5,3.4,3.4v11h10.4c1.8,0,3.2,1.4,3.2,3.1
		s-1.4,3.1-3.1,3.1h-10.5V44c0,1.6,0.4,3.4,1.2,4.6c1.1,1.5,3.1,2.4,5.1,2.4c1.7,0,3.2-0.7,4.7-1.9c0.9-0.7,1.8-1.3,2.7-1.3
		c1.8,0,3.1,1.3,3.1,3.2C1061.6,51.9,1061.1,52.8,1060.5,53.4L1060.5,53.4z"
			/>
			<path
				d="M1102.6,55.1c-3.5,1.6-8.1,2.1-12.4,2.1c-9.2,0-15.3-3.7-17.8-6.3c-0.7-0.7-1.1-1.7-1.1-2.4c0-1.7,1.2-3.4,3.4-3.4
		c0.7,0,1.6,0.4,2.3,1c2.7,2.3,6.2,5,13.7,5c3.4,0,6.1-0.4,8.1-1.2c2.9-1.2,4-3.4,4-4.9c0-3.2-2.3-4-5.9-4.8
		c-1.9-0.4-9.3-1.2-11.4-1.5c-6.5-0.9-12.6-3.2-12.6-10.5c0-1.7,0.4-3.3,1.4-4.8c2.2-3.6,7.2-6.3,16-6.3c6.7,0,11.3,1.5,15.1,4
		c1.2,0.8,1.8,1.7,1.8,2.7c0,1.6-1.2,3.3-3,3.3c-0.7,0-1.6-0.4-2.3-0.8c-3.3-1.9-6.7-3.1-11.7-3.1c-9.1,0-10.4,3.1-10.4,5
		c0,3,2.7,4.1,7.3,4.7c2.3,0.3,8.4,1,10.8,1.4c5.5,1,11.7,3,11.7,10.7C1109.7,49.7,1106.8,53.2,1102.6,55.1L1102.6,55.1z"
			/>
		</g>
		<g className="head">
			<path
				d="M3.3,56.8c1.7,0,2.8-0.7,3.4-2l4.2-10.1h14.7c2.3,0,4-2.4,3.1-4.5c-0.5-1.2-1.8-2-3.1-2H8.3c-1.3,0-2.6,0.8-3,2L0.2,52.3
		C-0.6,54.4,1,56.9,3.3,56.8z M45.3,56.8c2.8,0,4.2-2.3,3.4-4.5l-19-46.4c-0.5-1.2-1.8-2-3.1-2c-2.8,0-4.3,2.3-3.4,4.5l19.1,46.4
		C42.7,56,43.9,56.8,45.3,56.8L45.3,56.8z"
			/>
		</g>
		<g className="head">
			<path
				d="M365.8,7.3c0,1.6,1.6,3.2,4.2,3.2c4,0,7.7,1.3,8.9,5.1c0.4,1.3,1.8,2.4,3.7,2.4c6.9,0,2.1-14.1-12.5-14.1
		C367.2,3.9,365.8,5.3,365.8,7.3L365.8,7.3z"
			/>
			<path d="M348.2,56.8c2,0,3.7-1.6,3.7-3.7V7.6c0-2.1-1.6-3.7-3.7-3.7s-3.7,1.6-3.7,3.7v45.6C344.6,55.2,346.2,56.8,348.2,56.8z" />
			<path
				d="M369.9,56.8c25.6,0,25.6-31.5,0-31.5c-2,0-4.2,0.9-4.2,3.4c0,1.9,1.8,3.3,3.7,3.3c16.7,0,16.7,18,0,18
		c-1.9,0-3.7,1.4-3.7,3.3C365.8,56,367.9,56.8,369.9,56.8z"
			/>
		</g>
		<g className="head">
			<path
				d="M675.9,56.8c1.7,0,2.8-0.7,3.4-2l4.2-10.1h14.7c2.3,0,4-2.4,3.1-4.5c-0.5-1.2-1.8-2-3.1-2h-17.3c-1.3,0-2.6,0.8-3,2
		l-5,12.2C672,54.4,673.6,56.8,675.9,56.8L675.9,56.8z"
			/>
			<path
				d="M717.9,56.8c2.8,0,4.2-2.3,3.4-4.5l-19-46.4c-0.5-1.2-1.8-2-3.1-2c-2.8,0-4.3,2.3-3.4,4.5l19.1,46.4
		C715.3,56,716.5,56.8,717.9,56.8z"
			/>
		</g>
	</svg>
);

export const DownArrow = ({ className, fill }) => (
	<svg
		className={className}
		// width="26"
		// height="36"
		viewBox="0 0 26 36"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M25.1498 22.0801L13.1198 34.1101L1.08984 22.0801"
			stroke={fill}
			strokeWidth="2"
			strokeMiterlimit="10"
		/>
		<path
			d="M13.1172 34.1094V0.109375"
			stroke={fill}
			strokeWidth="2"
			strokeMiterlimit="10"
		/>
	</svg>
);
