import React from "react";
import "../styles/static.css";
import GlobalStyles from "../styles/global";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Transition from "../components/Transition";
import { useEffect } from "react";
import { useSiteState } from "../context/siteContext";
import styled from "@emotion/styled";
import { useLocation } from "@reach/router";

const Layout = ({ className, children, entered, scrolled }) => {
	const location = useLocation();
	const [siteState, setSiteState] = useSiteState();

	let page;
	const isProjects =
		location.pathname === "/projects" || location.pathname === "/projects/";
	if (location.pathname === "/") {
		page = "home";
	} else if (isProjects) {
		page = "projects";
	} else if (location.pathname.includes("/process")) {
		page = "process";
	} else if (location.pathname.includes("/practice")) {
		page = "practice";
	} else if (location.pathname.includes("/archive")) {
		page = "archive";
	} else if (location.pathname.includes("/404")) {
		page = "error";
	} else if (location.pathname.includes("/projects/") && !isProjects) {
		page = "project";
	}

	useEffect(() => {
		if (location.pathname !== "/") {
			setSiteState((prevSiteState) => ({
				...prevSiteState,
				entered: true,
				textEntered: true,
			}));
		}
	}, []);

	return (
		<Wrap className={`page-${page}`}>
			<GlobalStyles />
			<Transition>
				<Header page={page} scrolled={scrolled} />
				<main className={`page-${page}`}>
					{children}
					<Footer />
				</main>
			</Transition>
		</Wrap>
	);
};

const Wrap = styled.div``;

export default Layout;
