import React from "react";
import { PortableText } from "@portabletext/react";
import { css } from "@emotion/react";

const components = {};

const BlockText = ({ value }) => {
	return <PortableText value={value} components={components} />;
};

export default BlockText;
