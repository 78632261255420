import React, { useState, useEffect, useRef } from "react";
import { Global, css } from "@emotion/react";
import styled from "@emotion/styled";
import Section from "./Section";
import useSiteSettings from "../utils/useSiteSettings";
import { graphql, useStaticQuery } from "gatsby";
import { Logo } from "./Svg";
import { mobile, tablet, desktop, desktopUp } from "../styles/global";
import BlockText from "./BlockText";
import useWindow from "../utils/useWindow";

const Footer = ({ className }) => {
	const { address, email, tel, instagramUrl } = useSiteSettings();

	const [footerHeight, setFooterHeight] = useState();
	const w = useWindow();
	const footerRef = useRef();

	useEffect(() => {
		setFooterHeight(footerRef?.current?.offsetHeight);
	}, [footerRef, w]);

	return (
		<>
			<Global
				styles={css`
					:root {
						--footer-height: ${footerHeight}px;
					}
				`}
			/>
			<Wrap ref={footerRef}>
				<p>Andrew Burges Architects</p>
				<FooterInfo>
					<AddressWrap>
						<Address value={address} />
					</AddressWrap>
					<Contact>
						<a
							href={`mailto:${email}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							{email}
						</a>
						<a href={`tel:${tel}`} target="_blank" rel="noopener noreferrer">
							{tel}
						</a>
					</Contact>
					<IG>
						Follow us
						<br />
						<a target="_blank" rel="noopener noreferrer" href={instagramUrl}>
							Instagram
						</a>
					</IG>
				</FooterInfo>
			</Wrap>
		</>
	);
};

const Wrap = styled.footer`
	background-color: var(--grey-light);
	padding: var(--margin);
	display: flex;
	flex-direction: column;
	gap: var(--m);

	${mobile} {
		display: none;
	}
`;

const FooterInfo = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-column-gap: var(--gap);

	a {
		display: block;
	}
`;

const AddressWrap = styled.div`
	grid-column: 1 / span 3;
`;

const Address = styled(BlockText)``;

const Contact = styled.div`
	grid-column: 4 / span 3;
`;

const IG = styled.p`
	grid-column: 10/-1;
	justify-self: flex-end;
`;

export default Footer;
