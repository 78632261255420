import React from "react";
import styled from "@emotion/styled";
import { mobile } from "../styles/global";

const Section = ({ id, full, className, children, cols, mobCols }) => {
	return (
		<SectionWrap
			id={id}
			className={className}
			full={full}
			cols={cols}
			mobCols={mobCols}
		>
			{children}
		</SectionWrap>
	);
};

const SectionWrap = styled.div`
	display: grid;
	grid-template-columns: ${(props) =>
		`repeat(${props.cols ? props.cols : `12`}, 1fr)`};
	grid-column-gap: var(--gap);
	padding: 0 var(--margin);

	${mobile} {
		grid-template-columns: ${(props) =>
			props.mobCols ? `repeat(${props.cols ? props.cols : `1`}, 1fr)` : "1fr"};
	}

	> * {
		grid-column: ${(props) => props.full && "1/-1"};
	}
`;

export default Section;
